import { IHttpClient } from '@wix/yoshi-flow-editor';
import { getDraftGallery } from '@wix/ambassador-fastgallery-draftgallery-v1-draft-gallery/http';
import { getGallery } from '@wix/ambassador-fastgallery-gallery-v1-gallery/http';
import { queryItems } from '@wix/ambassador-fastgallery-item-v1-item/http';
import {
  queryDraftItems,
  bulkCreateDraftItems,
  bulkDeleteDraftItems,
  updateDraftItem,
  moveDraftItems,
} from '@wix/ambassador-fastgallery-draftitem-v1-draft-item/http';
import {
  BulkCreateDraftItemsResponse,
  DraftItem,
  Sorting,
  SortOrder,
} from '@wix/ambassador-fastgallery-draftitem-v1-draft-item/types';
import { createFastGalleryApp } from '@wix/ambassador-fastgallery-app-v1-fast-gallery-app/http';
import { ViewMode } from '../types/viewMode';

const ITEMS_LIMIT_DEFAULT = 25;

interface IFetcherParams {
  httpClient: IHttpClient;
  viewMode: ViewMode;
  galleryInstanceId: string;
}
export class FastGalleryService {
  private httpClient: IHttpClient;
  private viewMode: ViewMode;
  private galleryInstanceId: string;
  constructor(props: IFetcherParams) {
    const { httpClient, viewMode, galleryInstanceId } = props;
    this.httpClient = httpClient;
    this.viewMode = viewMode;
    this.galleryInstanceId = galleryInstanceId;
  }
  static async createGalleryInstance(
    httpClient: IHttpClient,
    originGalleryId?: string,
  ) {
    const { data } = await httpClient.request(
      createFastGalleryApp({ originDraftGalleryId: originGalleryId }),
    );
    return data.createdDraftGalleryId;
  }
  async getGalleryItemsInCurrentContext(limit: number = ITEMS_LIMIT_DEFAULT) {
    try {
      if (this.viewMode === 'EDITOR') {
        return await this.getDraftGalleryItems(limit);
      } else {
        return await this.getPublishedGalleryItems(limit);
      }
    } catch (err) {
      console.error('cound not fetch FastGallery items ', err);
    }
  }

  async getGalleryDataInCurrentContext() {
    try {
      if (this.viewMode === 'EDITOR') {
        return await this.getDraftGalleryData();
      } else {
        return await this.getPublishedGalleryData();
      }
    } catch (err) {
      console.error('cound not fetch FastGallery data ', err);
    }
  }
  async getDraftGalleryItems(limit: number = ITEMS_LIMIT_DEFAULT) {
    const response = await this.httpClient.request(
      queryDraftItems({
        query: {
          filter: {
            draftGalleryId: this.galleryInstanceId,
          },
          cursorPaging: {
            limit,
          },
        },
      }),
    );
    return response?.data.draftItems;
  }
  async getAllDraftGalleryItems() {
    const draftGalleryItems: DraftItem[] = [];
    let hasNext = false;
    let cursor: string | null = null;
    let filter: Record<string, any> | undefined = {
      draftGalleryId: this.galleryInstanceId,
    };
    let sort: Sorting[] | undefined = [
      { fieldName: 'sortOrder', order: SortOrder.ASC },
    ];
    do {
      try {
        const res = await this.httpClient.request(
          queryDraftItems({
            query: {
              filter,
              sort,
              cursorPaging: {
                limit: 100,
                cursor,
              },
            },
          }),
        );
        const { draftItems, pagingMetadata } = res.data;
        cursor = pagingMetadata?.cursors?.next || null;
        hasNext = (pagingMetadata?.hasNext as boolean) || false;
        filter = undefined;
        sort = undefined;
        draftItems && draftGalleryItems.push(...draftItems);
      } catch (error) {
        throw new Error('Failed to fetch draft items');
      }
    } while (hasNext);

    return draftGalleryItems;
  }

  async getPublishedGalleryItems(limit: number = ITEMS_LIMIT_DEFAULT) {
    const response = await this.httpClient.request(
      queryItems({
        query: {
          filter: {
            galleryId: this.galleryInstanceId,
          },
          cursorPaging: {
            limit,
          },
        },
      }),
    );
    return response?.data.items;
  }

  async getDraftGalleryData() {
    const response = await this.httpClient.request(
      getDraftGallery({
        draftGalleryId: this.galleryInstanceId,
      }),
    );
    return response.data.draftGallery;
  }

  async getPublishedGalleryData() {
    const response = await this.httpClient.request(
      getGallery({
        galleryId: this.galleryInstanceId,
      }),
    );
    return response.data.gallery;
  }

  async createDraftItemsInServer(
    draftItems: DraftItem[],
  ): Promise<DraftItem[] | undefined> {
    try {
      const response =
        await this.httpClient.request<BulkCreateDraftItemsResponse>(
          bulkCreateDraftItems({
            draftItems,
            returnEntity: true,
          }),
        );
      const createdItems = response.data.results?.flatMap(
        (result) => result.item || [],
      );
      return createdItems;
    } catch (err) {
      console.error('Failed to create draft items', err);
    }
  }
  async deleteDraftItemsInServer(draftItemsIds: string[]): Promise<void> {
    try {
      await this.httpClient.request(
        bulkDeleteDraftItems({
          draftItemIds: draftItemsIds,
        }),
      );
    } catch (err) {
      console.error('Failed to create draft items', err);
    }
  }
  async updateSingleDraftItemDataInServer(draftItem: DraftItem): Promise<void> {
    try {
      await this.httpClient.request(
        updateDraftItem({
          draftItem,
        }),
      );
    } catch (err) {
      console.error('Failed to create draft items', err);
    }
  }
  async updateItemSortOrderInServer(
    itemIds: string[],
    afterItemId?: string,
  ): Promise<void> {
    try {
      await this.httpClient.request(
        moveDraftItems({
          itemIds,
          afterItemId,
        }),
      );
    } catch (err) {
      console.error('Failed to create draft items', err);
    }
  }
}
