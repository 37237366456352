import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { createFastGallery } from './utils/installationUtils';
import { FastGalleryService } from '../../services/fastGalleryService';

export async function handleFirstInstall(
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> {
  try {
    const createdDraftGalleryId =
      await FastGalleryService.createGalleryInstance(flowAPI.httpClient);
    if (!createdDraftGalleryId) {
      throw new Error('no galleryId returned from the server');
    } else {
      const widgetRef = await createFastGallery(editorSDK);
      const [galleryCompRef] = await editorSDK.components.getChildren('token', {
        componentRef: widgetRef,
      });
      // Here we use the gallery compId to save on the document the gallery instance id
      await editorSDK.document.controllers.saveConfiguration('token', {
        controllerRef: galleryCompRef,
        config: {
          galleryId: createdDraftGalleryId,
        },
      });
    }
  } catch (error) {
    console.error('could not create FastGallery app ', error);
  }
}
