import { AppManifest } from '@wix/platform-editor-sdk';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const modifyAppManifest = async (
  options,
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  const manifest = appManifestBuilder.withJsonManifest(baseManifest).build();
  return manifest;
};
