import { WidgetBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetBuilder';
import { FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

const setDesktopGFPP = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  widgetBuilder.gfpp().set('mainAction1', {
    label: t('Manage media'),
    onClick: async ({ detail }) => {
      const { componentRef } = detail;
      const galleryId = (
        await editorSDK.document.controllers.getData('', {
          controllerRef: componentRef,
        })
      ).config.galleryId;
      editorSDK.editor.openModalPanel('', {
        title: 'Organized Media Panel',
        url: getPanelUrl('fastGalleryWidget', 'FgOrganizeMedia'),
        height: '90%' as any,
        width: '90%' as any,
        initialData: {
          componentRef,
          galleryId,
        },
        centered: true,
      });
    },
  });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  setDesktopGFPP(widgetBuilder, editorSDK, flowAPI);

  widgetBuilder.set({
    displayName: 'Fast Gallery',
    nickname: 'fastGalleryWidget',
  });
};
