import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { handleFirstInstall } from './first-install/handleFirstInstall';

export const onEditorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  await editorSDK.addEventListener('componentAddedToStage', async (event) => {
    console.log('componentAddedToStage ==>');
  });
  if (options.firstInstall) {
    handleFirstInstall(editorSDK, appDefId, options, flowAPI);
  }
};
