import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { addGalleryWidget } from '../createwidgetDefinition';

export const createFastGallery = async (editorSDK: FlowEditorSDK) => {
  // const compRef = await addFastGalleryAndExpandComps(editorSDK);
  const pageRef = await editorSDK.document.pages.getCurrent('token');
  const compRef = await addGalleryWidget(editorSDK, pageRef);
  return compRef;
  // await addExpandWidget(editorSDK, compRef);
};
