import { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { mockItems } from './utils/mocks';

export const addFastGalleryEditorElement = async (
  editorSDK: FlowEditorSDK,
  containerRef: ComponentRef,
) => {
  // install the fast gallery editor element
  const galleryDefinition =
    await editorSDK.document.components.createDefinition('', {
      componentType: 'wixui.FastGallery',
    });
  const fastGalleryCompRef = await editorSDK.document.components.add('token', {
    componentDefinition: {
      ...galleryDefinition,
      data: {
        ...galleryDefinition.data,
        items: mockItems,
      },
      layout: {
        x: 100,
        y: 80,
        height: 40,
        width: 150,
      },
      layouts: {
        componentLayout: {
          minHeight: {
            type: 'px',
            value: 280,
          },
          hidden: false,
          height: {
            type: 'auto',
          },
          type: 'ComponentLayout',
          width: {
            type: 'px',
            value: 700,
          },
        },
        itemLayout: {
          id: '',
          alignSelf: 'center',
          gridArea: {
            rowStart: 1,
            rowEnd: 2,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'center',
          type: 'GridItemLayout',
        },
      },
    },
    pageRef: containerRef,
  });

  return fastGalleryCompRef;
};

export const addFastGalleryExpandContainer = async (
  editorSDK: FlowEditorSDK,
  containerRef: ComponentRef,
) => {
  // install expand container
  const expandDefinition = await editorSDK.document.components.createDefinition(
    '',
    {
      componentType: 'wixui.ExpandedFastGalleryOverlay',
    },
  );
  const expandCompRef = await editorSDK.document.components.add('token', {
    componentDefinition: {
      ...expandDefinition,
      layout: {
        x: 100,
        y: 80,
        height: 40,
        width: 150,
      },
    },
    // fastGalleryCompRef
    pageRef: containerRef,
  });
  return expandCompRef;
};

export const addGalleryWidget = (
  editorSDK: FlowEditorSDK,
  containerRef: ComponentRef,
) => {
  return editorSDK.application.appStudioWidgets.addWidget('', {
    containerRef,
    widgetId: '8cba69eb-8291-4aba-8be6-6a7f3428e18b',
    installationType: 'closed' as WidgetInstallationType,
    layout: {
      height: 100,
      width: 100,
      x: 0,
      y: 100,
    },
    presetIds: {
      style: 'variants-lgnn37aw',
      layout: 'variants-lgnn37aw',
    },
    layouts: {
      componentLayout: {
        minHeight: {
          type: 'px',
          value: 280,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'percentage',
          value: 50,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        justifySelf: 'center',
        type: 'GridItemLayout',
      },
    },
  });
};

export const addExpandWidget = async (
  editorSDK: FlowEditorSDK,
  containerRef: ComponentRef,
) => {
  await editorSDK.application.appStudioWidgets.addWidget('', {
    containerRef,
    widgetId: '01f0b912-4854-46f4-9302-aa8fbf0f6afd',
    installationType: 'closed' as WidgetInstallationType,
    layout: {
      height: 100,
      width: 100,
      x: 0,
      y: 100,
    },
    presetIds: {
      style: 'variants-lyejjuc61',
      layout: 'variants-lyejjuc61',
    },
    layouts: {
      componentLayout: {
        minHeight: {
          type: 'px',
          value: 280,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'percentage',
          value: 50,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        justifySelf: 'center',
        type: 'GridItemLayout',
      },
    },
  });
};
